import React from 'react';
import './Home.scss';
import { Helmet } from 'react-helmet';
import Header from '../../Components/Header/Header';
import { CONSTANT, SEO } from '../../Utils/Constant';
import HeroSection from '../../Components/HeroSection/HeroSection';
import WeServe from '../../Components/WeServe/WeServe';
import CareerContact from '../../Components/CareerContact/CareerContact';
import Contact from '../../Components/Contact/Contact';
import Services from '../../Components/Services/Services';
import ProminentWorks from '../../Components/ProminentWorks/ProminentWorks';
import ClientReview from '../../Components/ClientReview/ClientReview';
import Agencies from '../../Components/Agencies/Agencies';
import Footer from '../../Components/Footer/Footer';
import Counter from '../../Components/Counter/Counter';
import Blogs from '../../Components/Blogs/Blogs';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>{SEO.HOME_PAGE_TITLE}</title>
        <meta name="description" content={SEO.HOME_PAGE_DESCRIPTION} />
        <meta name="keywords" content={SEO.HOME_PAGE_KEYWORDS} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={SEO.HOME_PAGE_TITLE} />
        <meta property="og:description" content={SEO.HOME_PAGE_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.PIXERFECT_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.PIXERFECT_SOLUTIONS_LLP} />
        <meta property="og:image" content="https://www.pixerfect.com/Group_192*192.png" />
        <meta property="twitter:image" content="https://www.pixerfect.com/Group_192*192.png" />
        <meta name="twitter:title" content={SEO.HOME_PAGE_TITLE} />
        <meta name="twitter:description" content={SEO.HOME_PAGE_DESCRIPTION} />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json" data-react-helmet="true">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'Pixerfect Solutions LLP',
            alternateName: 'Pixerfect',
            url: 'https://www.pixerfect.com',
            logo: 'https://www.pixerfect.com/Group_192*192.png',
            contactPoint: [
              {
                '@type': 'ContactPoint',
                telephone: '+91 63556 23396',
                contactType: 'customer service',
                contactOption: 'HearingImpairedSupported',
                areaServed: [
                  'GB',
                  'CA',
                  'AF',
                  'AX',
                  'AL',
                  'DZ',
                  'AS',
                  'AD',
                  'AO',
                  'AI',
                  'AQ',
                  'AG',
                  'AR',
                  'BT',
                  'BO',
                  'AU',
                  'AT',
                  'BD',
                  'BY',
                  'KH',
                  'CN',
                  'KM',
                  'GL',
                  'GR',
                  'HK',
                  'IS',
                  'IN',
                  'ID',
                  'IR',
                  'IQ',
                  'IM',
                  'IL',
                  'IT',
                  'JP',
                  'JM',
                  'JE',
                  'JO',
                  'LV',
                  'MV',
                  'MY',
                  'MT',
                  'MX',
                  'NP',
                  'NL',
                  'NZ',
                  'KP',
                  'MP',
                  'NO',
                  'OM',
                  'PA',
                  'PH',
                  'PL',
                  'PT',
                  'QA',
                  'CG',
                  'RE',
                  'RO',
                  'RU',
                  'CH',
                  'SE',
                  'SY',
                  'TW',
                  'TH',
                  'TR',
                  'UG',
                  'UA',
                  'AE',
                  'UM',
                  'UZ',
                  'VN',
                  'EH',
                  'ZM',
                  '002',
                  '015',
                  '142',
                  '030',
                  '143',
                  '035',
                  '034',
                  '145',
                  '150',
                  '151',
                  '154',
                  '039',
                  '155',
                  'US'
                ],
                availableLanguage: ['en', 'Hindi', 'Gujarati']
              },
              {
                '@type': 'ContactPoint',
                telephone: '+91 63556 23396',
                contactType: 'technical support',
                contactOption: 'HearingImpairedSupported',
                areaServed: [
                  'GB',
                  'CA',
                  'AF',
                  'AX',
                  'AL',
                  'DZ',
                  'AS',
                  'AD',
                  'AO',
                  'AI',
                  'AQ',
                  'AG',
                  'AR',
                  'BT',
                  'BO',
                  'AU',
                  'AT',
                  'BD',
                  'BY',
                  'KH',
                  'CN',
                  'KM',
                  'GL',
                  'GR',
                  'HK',
                  'IS',
                  'IN',
                  'ID',
                  'IR',
                  'IQ',
                  'IM',
                  'IL',
                  'IT',
                  'JP',
                  'JM',
                  'JE',
                  'JO',
                  'LV',
                  'MV',
                  'MY',
                  'MT',
                  'MX',
                  'NP',
                  'NL',
                  'NZ',
                  'KP',
                  'MP',
                  'NO',
                  'OM',
                  'PA',
                  'PH',
                  'PL',
                  'PT',
                  'QA',
                  'CG',
                  'RE',
                  'RO',
                  'RU',
                  'CH',
                  'SE',
                  'SY',
                  'TW',
                  'TH',
                  'TR',
                  'UG',
                  'UA',
                  'AE',
                  'UM',
                  'UZ',
                  'VN',
                  'EH',
                  'ZM',
                  '002',
                  '015',
                  '142',
                  '030',
                  '143',
                  '035',
                  '034',
                  '145',
                  '150',
                  '151',
                  '154',
                  '039',
                  '155',
                  'US'
                ],
                availableLanguage: ['en', 'Hindi', 'Gujarati']
              },
              {
                '@type': 'ContactPoint',
                telephone: '+91 63556 23396',
                contactType: 'emergency',
                contactOption: 'HearingImpairedSupported',
                areaServed: [
                  'GB',
                  'CA',
                  'AF',
                  'AX',
                  'AL',
                  'DZ',
                  'AS',
                  'AD',
                  'AO',
                  'AI',
                  'AQ',
                  'AG',
                  'AR',
                  'BT',
                  'BO',
                  'AU',
                  'AT',
                  'BD',
                  'BY',
                  'KH',
                  'CN',
                  'KM',
                  'GL',
                  'GR',
                  'HK',
                  'IS',
                  'IN',
                  'ID',
                  'IR',
                  'IQ',
                  'IM',
                  'IL',
                  'IT',
                  'JP',
                  'JM',
                  'JE',
                  'JO',
                  'LV',
                  'MV',
                  'MY',
                  'MT',
                  'MX',
                  'NP',
                  'NL',
                  'NZ',
                  'KP',
                  'MP',
                  'NO',
                  'OM',
                  'PA',
                  'PH',
                  'PL',
                  'PT',
                  'QA',
                  'CG',
                  'RE',
                  'RO',
                  'RU',
                  'CH',
                  'SE',
                  'SY',
                  'TW',
                  'TH',
                  'TR',
                  'UG',
                  'UA',
                  'AE',
                  'UM',
                  'UZ',
                  'VN',
                  'EH',
                  'ZM',
                  '002',
                  '015',
                  '142',
                  '030',
                  '143',
                  '035',
                  '034',
                  '145',
                  '150',
                  '151',
                  '154',
                  '039',
                  '155',
                  'US'
                ],
                availableLanguage: ['en', 'Hindi', 'Gujarati']
              }
            ],
            sameAs: [
              'https://www.facebook.com/pixerfectsolutions',
              'https://x.com/pixerfect',
              'https://www.instagram.com/pixerfectsolutions/',
              'https://www.linkedin.com/company/pixerfectsolutions/'
            ]
          })}
        </script>
      </Helmet>
      <>
        <Header />
        <HeroSection />
        <WeServe />
        <ProminentWorks />
        <Agencies />
        <Counter />
        <Services />
        <ClientReview />
        <Blogs />
        <Contact />
        <CareerContact />
        <Footer />
      </>
    </>
  );
};

export default Home;
