import React from 'react';
import { Helmet } from 'react-helmet';
import { CONSTANT, SEO } from '../../Utils/Constant';
import CoreValues from './CoreValues';
import TechPartner from './TechPartner';
import AboutDetails from './AboutDetails';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Contact from '../../Components/Contact/Contact';
import Counter from '../../Components/Counter/Counter';
import HeroText from '../../Components/HeroText/HeroText';
import ClientReview from '../../Components/ClientReview/ClientReview';
import CareerContact from '../../Components/CareerContact/CareerContact';
import './AboutUs.scss';

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>{SEO.ABOUT_US_PAGE_TITLE}</title>
        <meta name="description" content={SEO.ABOUT_US_PAGE_DESCRIPTION} />
        <meta name="keywords" content={SEO.ABOUT_US_PAGE_KEYWORDS} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={SEO.ABOUT_US_PAGE_TITLE} />
        <meta property="og:description" content={SEO.ABOUT_US_PAGE_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.PIXERFECT_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.PIXERFECT_SOLUTIONS_LLP} />
        <meta property="og:image" content="https://www.pixerfect.com/Group_192*192.png" />
        <meta property="twitter:image" content="https://www.pixerfect.com/Group_192*192.png" />
        <meta name="twitter:title" content={SEO.ABOUT_US_PAGE_TITLE} />
        <meta name="twitter:description" content={SEO.ABOUT_US_PAGE_DESCRIPTION} />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json" data-react-helmet="true">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'Pixerfect Solutions LLP',
            alternateName: 'Pixerfect',
            url: 'https://www.pixerfect.com',
            logo: 'https://www.pixerfect.com/Group_192*192.png',
            contactPoint: [
              {
                '@type': 'ContactPoint',
                telephone: '+91 63556 23396',
                contactType: 'customer service',
                contactOption: 'HearingImpairedSupported',
                areaServed: [
                  'GB',
                  'CA',
                  'AF',
                  'AX',
                  'AL',
                  'DZ',
                  'AS',
                  'AD',
                  'AO',
                  'AI',
                  'AQ',
                  'AG',
                  'AR',
                  'BT',
                  'BO',
                  'AU',
                  'AT',
                  'BD',
                  'BY',
                  'KH',
                  'CN',
                  'KM',
                  'GL',
                  'GR',
                  'HK',
                  'IS',
                  'IN',
                  'ID',
                  'IR',
                  'IQ',
                  'IM',
                  'IL',
                  'IT',
                  'JP',
                  'JM',
                  'JE',
                  'JO',
                  'LV',
                  'MV',
                  'MY',
                  'MT',
                  'MX',
                  'NP',
                  'NL',
                  'NZ',
                  'KP',
                  'MP',
                  'NO',
                  'OM',
                  'PA',
                  'PH',
                  'PL',
                  'PT',
                  'QA',
                  'CG',
                  'RE',
                  'RO',
                  'RU',
                  'CH',
                  'SE',
                  'SY',
                  'TW',
                  'TH',
                  'TR',
                  'UG',
                  'UA',
                  'AE',
                  'UM',
                  'UZ',
                  'VN',
                  'EH',
                  'ZM',
                  '002',
                  '015',
                  '142',
                  '030',
                  '143',
                  '035',
                  '034',
                  '145',
                  '150',
                  '151',
                  '154',
                  '039',
                  '155',
                  'US'
                ],
                availableLanguage: ['en', 'Hindi', 'Gujarati']
              },
              {
                '@type': 'ContactPoint',
                telephone: '+91 63556 23396',
                contactType: 'technical support',
                contactOption: 'HearingImpairedSupported',
                areaServed: [
                  'GB',
                  'CA',
                  'AF',
                  'AX',
                  'AL',
                  'DZ',
                  'AS',
                  'AD',
                  'AO',
                  'AI',
                  'AQ',
                  'AG',
                  'AR',
                  'BT',
                  'BO',
                  'AU',
                  'AT',
                  'BD',
                  'BY',
                  'KH',
                  'CN',
                  'KM',
                  'GL',
                  'GR',
                  'HK',
                  'IS',
                  'IN',
                  'ID',
                  'IR',
                  'IQ',
                  'IM',
                  'IL',
                  'IT',
                  'JP',
                  'JM',
                  'JE',
                  'JO',
                  'LV',
                  'MV',
                  'MY',
                  'MT',
                  'MX',
                  'NP',
                  'NL',
                  'NZ',
                  'KP',
                  'MP',
                  'NO',
                  'OM',
                  'PA',
                  'PH',
                  'PL',
                  'PT',
                  'QA',
                  'CG',
                  'RE',
                  'RO',
                  'RU',
                  'CH',
                  'SE',
                  'SY',
                  'TW',
                  'TH',
                  'TR',
                  'UG',
                  'UA',
                  'AE',
                  'UM',
                  'UZ',
                  'VN',
                  'EH',
                  'ZM',
                  '002',
                  '015',
                  '142',
                  '030',
                  '143',
                  '035',
                  '034',
                  '145',
                  '150',
                  '151',
                  '154',
                  '039',
                  '155',
                  'US'
                ],
                availableLanguage: ['en', 'Hindi', 'Gujarati']
              },
              {
                '@type': 'ContactPoint',
                telephone: '+91 63556 23396',
                contactType: 'emergency',
                contactOption: 'HearingImpairedSupported',
                areaServed: [
                  'GB',
                  'CA',
                  'AF',
                  'AX',
                  'AL',
                  'DZ',
                  'AS',
                  'AD',
                  'AO',
                  'AI',
                  'AQ',
                  'AG',
                  'AR',
                  'BT',
                  'BO',
                  'AU',
                  'AT',
                  'BD',
                  'BY',
                  'KH',
                  'CN',
                  'KM',
                  'GL',
                  'GR',
                  'HK',
                  'IS',
                  'IN',
                  'ID',
                  'IR',
                  'IQ',
                  'IM',
                  'IL',
                  'IT',
                  'JP',
                  'JM',
                  'JE',
                  'JO',
                  'LV',
                  'MV',
                  'MY',
                  'MT',
                  'MX',
                  'NP',
                  'NL',
                  'NZ',
                  'KP',
                  'MP',
                  'NO',
                  'OM',
                  'PA',
                  'PH',
                  'PL',
                  'PT',
                  'QA',
                  'CG',
                  'RE',
                  'RO',
                  'RU',
                  'CH',
                  'SE',
                  'SY',
                  'TW',
                  'TH',
                  'TR',
                  'UG',
                  'UA',
                  'AE',
                  'UM',
                  'UZ',
                  'VN',
                  'EH',
                  'ZM',
                  '002',
                  '015',
                  '142',
                  '030',
                  '143',
                  '035',
                  '034',
                  '145',
                  '150',
                  '151',
                  '154',
                  '039',
                  '155',
                  'US'
                ],
                availableLanguage: ['en', 'Hindi', 'Gujarati']
              }
            ],
            sameAs: [
              'https://www.facebook.com/pixerfectsolutions',
              'https://x.com/pixerfect',
              'https://www.instagram.com/pixerfectsolutions/',
              'https://www.linkedin.com/company/pixerfectsolutions/'
            ]
          })}
        </script>
      </Helmet>
      <Header />
      <HeroText
        title={'Your Vision, Our Mission'}
        content={
          'We excel in delivering bespoke software solutions. Our expertise lies in crafting comprehensive, tailor-made software to seamlessly align with the diverse needs of businesses.'
        }
      />
      <AboutDetails />
      <TechPartner />
      <CoreValues />
      <ClientReview />
      <Counter />
      <Contact />
      <CareerContact />
      <Footer />
    </>
  );
};

export default AboutUs;
