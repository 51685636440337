import React from 'react';
import Map from '../../Components/Map/Map';
import Faq from '../../Components/Faq/Faq';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import ContactDetail from '../../Components/ContactDetail/ContactDetail';
import CareerContact from '../../Components/CareerContact/CareerContact';
import { Helmet } from 'react-helmet';
import { CONSTANT, SEO } from '../../Utils/Constant';

const ContactPage = () => {
  return (
    <div>
      <Helmet>
        <title>{SEO.CONTACT_PAGE_TITLE}</title>
        <meta name="description" content={SEO.CONTACT_PAGE_DESCRIPTION} />
        <meta name="keywords" content={SEO.CONTACT_PAGE_KEYWORDS} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={SEO.CONTACT_PAGE_TITLE} />
        <meta property="og:description" content={SEO.CONTACT_PAGE_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.PIXERFECT_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.PIXERFECT_SOLUTIONS_LLP} />
        <meta property="og:image" content="https://www.pixerfect.com/Group_192*192.png" />
        <meta property="twitter:image" content="https://www.pixerfect.com/Group_192*192.png" />
        <meta name="twitter:title" content={SEO.CONTACT_PAGE_TITLE} />
        <meta name="twitter:description" content={SEO.CONTACT_PAGE_DESCRIPTION} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      <ContactDetail />
      <Map />
      <Faq />
      <CareerContact />
      <Footer />
    </div>
  );
};

export default ContactPage;
